@import 'animate.min.css';
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
/**
*Class	描述
.active	鼠标悬停在行或单元格上时所设置的颜色
.success	标识成功或积极的动作
.info	标识普通的提示信息或动作
.warning	标识警告或需要用户注意
.danger	标识危险或潜在的带来负面影响的动作
*/
/*定制主题
https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
由于primary-*是基于primary-color计算，暗色主题颜色会生成灰色primary-1,手动指定亮色
*/
@import 'loading.css';
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
/* Sections
     ========================================================================== */
/**
   * Remove the margin in all browsers.
   */
body {
  margin: 0;
  font-size: 14px;
}
/**
   * Render the `main` element consistently in IE.
   */
main {
  display: block;
}
/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background on active links in IE 10.
   */
body a {
  text-decoration: none;
  color: #166eff;
  text-decoration-color: initial;
  background-color: transparent;
  outline-color: initial;
  transition: color 0.3s;
}
body a:hover {
  text-decoration-color: initial;
  outline-color: initial;
  color: #59ace8;
}
/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}
/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder;
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%;
}
/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/* Embedded content
     ========================================================================== */
/**
   * Remove the border on images inside links in IE 10.
   */
img {
  border-style: none;
}
/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
   * Restore the focus styles unset by the previous rule.
   */
/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline;
}
/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto;
}
/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}
/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block;
}
/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item;
}
/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
  display: none;
}
/**
   * Add the correct display in IE 10.
   */
[hidden] {
  display: none;
}
HTML,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
p,
blockquote,
pre,
form,
fieldset,
table,
th,
td,
i {
  border: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  outline: none;
}
div {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
}
address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}
input::-ms-clear {
  display: none;
}
input::-ms-reveal {
  display: none;
}
input {
  -webkit-appearance: none;
  margin: 0;
  outline: none;
  padding: 0;
}
input::-webkit-input-placeholder {
  color: #ccc;
}
input::-ms-input-placeholder {
  color: #ccc;
}
input::-moz-placeholder {
  color: #ccc;
}
input[type="submit"],
input[type="button"] {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
img {
  border: none;
}
ul,
ol,
li {
  list-style-type: none;
}
th {
  font-weight: 700;
}
::selection {
  text-shadow: none;
  background: #166EFF;
}
::-webkit-selection {
  background: #166EFF;
  color: #fff;
}
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
body::-webkit-scrollbar {
  display: none;
}
p {
  margin: 0;
  padding: 0;
}
table.pure-table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px 2px 0 0;
}
table.pure-table th,
table.pure-table td {
  word-break: break-all;
  padding: 8px 20px 8px 0;
}
table.pure-table.bordered {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
table.pure-table.bordered th,
table.pure-table.bordered td {
  padding: 8px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
iframe {
  box-sizing: border-box;
  outline: none;
  border: none;
}
iframe body {
  background-color: #fff;
  color: #000;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.danger-color {
  color: #f44455;
}
.danger-color:hover {
  color: #f77a99;
}
.placeholder-color {
  color: #bfbfbf;
}
.grey-color {
  color: #909599;
}
.red-color {
  color: #FF0000;
}
.pink-color {
  color: #FF3A77;
}
.operate-color {
  color: #0080c2;
}
[theme-color] {
  color: #166EFF !important;
}
[theme-bg] {
  background-color: #166EFF !important;
  border-color: #166EFF !important;
  color: #fff !important;
}
[theme-bg]:disabled {
  background-color: #d07b83 !important;
  border-color: #d07b83 !important;
}
.ant-breadcrumb > span {
  color: #000000;
}
.ant-breadcrumb-separator {
  margin: 0 10px !important;
}
.ant-table colgroup col {
  min-width: 80px;
}
.ant-table .ant-table-header th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #c1c1c1;
}
.rdw-editor-main .DraftEditor-root {
  height: auto;
}
.ant-modal .ant-modal-footer {
  text-align: center;
}
.ant-spin-container {
  height: 100%;
}
.drawer-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: right;
  padding: 14px 24px;
  box-shadow: 0 -1px 0 0 rgba(218, 218, 218, 0.5);
}
.drawer-footer .ant-btn {
  margin-left: 8px;
}
.ant-modal-fullscreen {
  position: absolute !important;
  top: 0;
  right: 56px;
  z-index: 10;
  margin: 12px 4px;
  color: rgba(42, 43, 60, 0.45) !important;
}
.ant-layout .ant-breadcrumb .ant-breadcrumb-link,
.ant-layout .ant-breadcrumb a {
  color: rgba(42, 43, 60, 0.85);
}
.ant-layout .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #e3eeff;
  color: #166eff;
  position: relative;
}
.ant-select-tree-list-holder-inner,
.ant-tree-list-holder-inner {
  display: block!important;
}
.ant-tree-node-content-wrapper {
  flex-grow: 1;
}
.margin-t-20 {
  margin-top: 20px;
}
.white-page {
  background-color: #fff;
  padding: 14px 20px;
}
.on-row table tbody tr {
  cursor: pointer;
}
.on-row table tbody tr:hover {
  color: #166EFF;
}
.cut-line {
  height: 20px;
  margin: 10px -24px 0 -24px;
  border-bottom: 10px solid #F2F5F7;
}
.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.f-14 {
  font-size: 14px;
}
.f-24 {
  font-size: 24px;
}
.f-18 {
  font-size: 18px;
}
.fw-600 {
  font-weight: 600;
}
.ml7 {
  margin-left: 7px;
}
a.link:hover {
  text-decoration: underline;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.b-r {
  border-right: 1rpx solid #dbdbdb;
}
.num-border {
  display: inline-block;
  min-width: 34px;
  text-align: center;
  padding: 4px 8px;
  line-height: 1;
  border: 1px solid #007EFF;
  border-radius: 3px;
  color: #007EFF;
}
.blue-color {
  color: #166EFF;
}
.mw-100 {
  max-width: 100%!important;
}
.new-detail > * {
  margin-bottom: 20px;
}
.new-detail > *:last-child {
  margin-bottom: 0;
}
.new-detail .news-intro {
  font-size: 16px;
  color: #a8a8a8;
}
.new-detail img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  margin-bottom: 6px;
}
.ant-layout .ant-menu-root.ant-menu-inline,
.ant-layout .ant-menu-root.ant-menu-vertical {
  background: #fff !important;
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline a,
.ant-layout .ant-menu-root.ant-menu-vertical a {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu {
  color: #616161 !important;
  background-color: #fff !important;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu > .ant-menu-submenu-title:hover,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu > .ant-menu-submenu-title:hover {
  background-color: #EBEBEB;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-selected,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-selected {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-active,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-active {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu > .ant-menu,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu > .ant-menu {
  background-color: rgba(48, 126, 255, 0.15);
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-item > a:hover,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-item > a:hover {
  color: #616161;
}
#root .ant-menu .ant-menu-item-selected {
  background-color: #E3EEFF !important;
  color: #166EFF !important;
  position: relative;
}
#root .ant-menu .ant-menu-item-selected::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #166EFF;
}
#root .ant-menu .ant-menu-item-selected span a {
  color: #166EFF !important;
}
#root .ant-menu .ant-menu-item {
  color: #616161;
}
#root .ant-menu .ant-menu-item span a {
  color: #616161;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  color: #616161;
}
[class*=btn] {
  cursor: pointer;
}
.bg--primary {
  background: #166EFF;
}
.header-logo {
  color: #fff;
  line-height: 48px;
  text-align: center;
  font-size: 17px;
  display: flex;
  align-items: center;
}
.header-logo .logo-circle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #153ad1;
  border-radius: 50%;
  margin-right: 20px;
}
.header-logo .logo-circle img {
  width: 26px;
  border-radius: 50%;
  height: auto;
  object-fit: cover;
}
.header {
  background-color: #166EFF !important;
  padding: 0 40px;
  height: 48px !important;
  line-height: 48px !important;
  align-items: center;
  justify-content: space-between;
  color: #fff !important;
}
.header .header-right {
  display: flex;
  align-items: center;
  color: #c7c7c7;
}
.header .contact-us {
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
  color: #fff;
}
.header .contact-us::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 16px;
  bottom: 16px;
  width: 1px;
  background: #fff;
}
.header .contact-us span {
  margin-left: 8px;
}
.header .userinfo {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.header .userinfo img {
  margin-right: 20px;
}
.header .userinfo p:nth-child(2) {
  margin: 0 6px;
}
.header .userinfo p:nth-child(2) span {
  font-size: 16px;
  color: #fff;
}
.header .ant-menu {
  background: transparent;
  color: #ffffff;
}
.header .ant-menu .ant-menu-item:hover {
  color: #ffffff;
}
.header__trigger {
  color: #ffffff;
}
.ant-layout-content {
  min-height: auto;
  margin: 8px 16px;
}
.ant-layout.ant-layout-has-sider.app_layout-mobile {
  flex-direction: column;
}
.ant-layout.ant-layout-has-sider.app_layout-mobile .ant-layout-content {
  margin: 0;
}
.ant-layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.ant-layout .ant-layout-sider-children .ant-spin-nested-loading {
  flex: 1 1 0%;
  overflow: hidden auto;
}
.ant-layout .ant-layout-sider-children .side-link {
  padding-left: 16px;
  line-height: 40px;
  border-top: 1px solid #3e4141;
  color: #fff;
}
.ant-layout .ant-menu-root.ant-menu-inline,
.ant-layout .ant-menu-dark .ant-menu-item {
  color: rgba(255, 255, 255, 0.85);
}
.ant-layout .ant-menu-dark .ant-menu-item:hover {
  color: #fff;
}
.wrapper-admin-box {
  position: relative;
}
.admin-box {
  position: relative;
  margin: 0;
  padding: 14px 16px;
  height: calc(100vh - 48px);
  overflow: auto;
}
.admin-box div:last-child:fullscreen {
  background-color: #f5f5f5;
}
.ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  background-image: url("/static/images/login-bg.jpg");
  background-size: 100% 100%;
  background-color: #eff7ff;
}
.login .login-box {
  width: 1000px;
  margin: auto;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  background-image: url("/static/images/login-right.png");
  background-size: 100% 100%;
}
.login .login-box .login-left {
  width: 50%;
  color: #fff;
  padding: 40px;
  background-image: linear-gradient(to right bottom, #24c7fc, #0348f9);
}
.login .login-box .login-left .top-line {
  width: 51px;
  height: 8px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-bottom: 33px;
}
.login .login-box .login-left h1 {
  margin-bottom: 24px;
}
.login .login-box .login-left h2 {
  font-size: 16px;
  letter-spacing: 2px;
}
.login .login-box .login-left .h1-line {
  width: 40px;
  height: 2px;
  background: #fff;
}
.login .login-box .login-left h1,
.login .login-box .login-left h2 {
  color: #fff;
}
.login .login-box .login-left img {
  width: 350px;
  object-fit: contain;
}
.login .login-form-box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .login-form {
  width: 400px;
  position: relative;
  flex-shrink: 0;
  border-radius: 4px;
}
.login .login-form h1 {
  font-size: 35px;
  font-weight: bold;
  color: #4e6ef2;
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
  margin-left: 50%;
  letter-spacing: 6px;
  transform: translateX(-50%);
}
.login .login-form h1::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #cce4ff;
  border-radius: 3px;
}
.login .login-form .ant-input-affix-wrapper {
  padding: 9px 16px;
}
.login .login-form .login-logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}
.login .login-form .login-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}
.login .register-operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login .register-operation .register-btn {
  font-weight: 500;
  color: #4e6ef2;
}
.login .register-operation span.register-tip {
  color: #828282;
  margin-right: 6px;
}
.login .copyright {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
  font-weight: 400;
  color: #e4e4e4;
}
.login .copyright img {
  height: 20px;
  object-fit: cover;
}
.installer {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #313653 transparent transparent;
  position: fixed;
  top: 0;
  right: 0;
}
.installer__btn {
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  left: 50px;
  position: absolute;
  background-size: cover;
}
.register {
  background: #fff;
}
.register .login-form-box {
  display: flex;
  position: relative;
  justify-content: center;
  padding: 60px 50px;
}
.register .login-form-box .back-btn {
  position: absolute;
  left: 50px;
  border: 1px solid #d8d8d8;
  border-radius: 17px;
  color: #4e6ef2;
  padding: 2px 16px ;
}
.register .login-form {
  width: 800px;
  position: relative;
}
.register .login-form h1 {
  font-size: 35px;
  font-weight: bold;
  color: #828282;
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  letter-spacing: 6px;
}
.register .login-form h1::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
  height: 4px;
  background: #CCE4FF;
  border-radius: 3px;
}
.register .login-form .login-logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}
.register .login-form .login-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}
.verify {
  background: #fff;
}
.verify .login-form-box {
  display: flex;
  position: relative;
  justify-content: center;
  padding: 60px 50px;
}
.verify .login-form-box .back-btn {
  position: absolute;
  left: 50px;
  border: 1px solid #d8d8d8;
  border-radius: 17px;
  color: #4e6ef2;
  padding: 2px 16px;
}
.verify .login-form {
  width: 500px;
  position: relative;
}
.verify .login-form h1 {
  font-size: 35px;
  font-weight: bold;
  color: #828282;
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  letter-spacing: 6px;
}
.verify .login-form h1::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
  height: 4px;
  background: #cce4ff;
  border-radius: 3px;
}
.verify .login-form .login-logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}
.verify .login-form .login-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}
.verify .status {
  text-align: center;
  margin-top: 80px;
}
.verify .status img {
  width: 300px;
  object-fit: cover;
}
.verify .status .text {
  font-size: 22px;
  font-weight: 500;
  margin-top: -30px;
}
.verify .status .reason {
  font-size: 18px;
  color: #c90f27;
  margin-top: 20px;
}
.verify .status-1 .text,
.verify .status2 .text {
  color: #c90f27;
}
.verify .status1 .text {
  color: #00a780;
}
.verify .status0 .text {
  color: #4e6ef2;
}
.col-12 {
  width: 100%;
}
.col-offset-12 {
  margin-left: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
.left {
  margin-right: auto;
}
.right {
  margin-left: auto;
}
.top {
  margin-bottom: auto;
}
.bottom {
  margin-top: auto;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.middle {
  margin-top: auto;
  margin-bottom: auto;
}
.inline-flex {
  display: inline-flex;
}
.flex {
  display: flex;
}
.flex-left {
  justify-content: left;
}
.flex-center {
  justify-content: center;
}
.flex-right {
  justify-content: flex-end;
}
.flex-top {
  align-items: flex-start;
}
.flex-middle {
  align-items: center;
}
.flex-bottom {
  align-items: flex-end;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}
.flex-around {
  justify-content: space-around;
}
.flex-between {
  justify-content: space-between;
}
.flex-column {
  flex-direction: column;
}
.flex-baseline {
  align-items: baseline;
}
.flex-col-1 {
  width: 4.16666667%;
}
.flex-offset-1 {
  margin-left: 4.16666667%;
}
.flex-order-1 {
  order: 1;
}
.flex-col-2 {
  width: 8.33333333%;
}
.flex-offset-2 {
  margin-left: 8.33333333%;
}
.flex-order-2 {
  order: 2;
}
.flex-col-3 {
  width: 12.5%;
}
.flex-offset-3 {
  margin-left: 12.5%;
}
.flex-order-3 {
  order: 3;
}
.flex-col-4 {
  width: 16.66666667%;
}
.flex-offset-4 {
  margin-left: 16.66666667%;
}
.flex-order-4 {
  order: 4;
}
.flex-col-5 {
  width: 20.83333333%;
}
.flex-offset-5 {
  margin-left: 20.83333333%;
}
.flex-order-5 {
  order: 5;
}
.flex-col-6 {
  width: 25%;
}
.flex-offset-6 {
  margin-left: 25%;
}
.flex-order-6 {
  order: 6;
}
.flex-col-7 {
  width: 29.16666667%;
}
.flex-offset-7 {
  margin-left: 29.16666667%;
}
.flex-order-7 {
  order: 7;
}
.flex-col-8 {
  width: 33.33333333%;
}
.flex-offset-8 {
  margin-left: 33.33333333%;
}
.flex-order-8 {
  order: 8;
}
.flex-col-9 {
  width: 37.5%;
}
.flex-offset-9 {
  margin-left: 37.5%;
}
.flex-order-9 {
  order: 9;
}
.flex-col-10 {
  width: 41.66666667%;
}
.flex-offset-10 {
  margin-left: 41.66666667%;
}
.flex-order-10 {
  order: 10;
}
.flex-col-11 {
  width: 45.83333333%;
}
.flex-offset-11 {
  margin-left: 45.83333333%;
}
.flex-order-11 {
  order: 11;
}
.flex-col-12 {
  width: 50%;
}
.flex-offset-12 {
  margin-left: 50%;
}
.flex-order-12 {
  order: 12;
}
.flex-col-13 {
  width: 54.16666667%;
}
.flex-offset-13 {
  margin-left: 54.16666667%;
}
.flex-order-13 {
  order: 13;
}
.flex-col-14 {
  width: 58.33333333%;
}
.flex-offset-14 {
  margin-left: 58.33333333%;
}
.flex-order-14 {
  order: 14;
}
.flex-col-15 {
  width: 62.5%;
}
.flex-offset-15 {
  margin-left: 62.5%;
}
.flex-order-15 {
  order: 15;
}
.flex-col-16 {
  width: 66.66666667%;
}
.flex-offset-16 {
  margin-left: 66.66666667%;
}
.flex-order-16 {
  order: 16;
}
.flex-col-17 {
  width: 70.83333333%;
}
.flex-offset-17 {
  margin-left: 70.83333333%;
}
.flex-order-17 {
  order: 17;
}
.flex-col-18 {
  width: 75%;
}
.flex-offset-18 {
  margin-left: 75%;
}
.flex-order-18 {
  order: 18;
}
.flex-col-19 {
  width: 79.16666667%;
}
.flex-offset-19 {
  margin-left: 79.16666667%;
}
.flex-order-19 {
  order: 19;
}
.flex-col-20 {
  width: 83.33333333%;
}
.flex-offset-20 {
  margin-left: 83.33333333%;
}
.flex-order-20 {
  order: 20;
}
.flex-col-21 {
  width: 87.5%;
}
.flex-offset-21 {
  margin-left: 87.5%;
}
.flex-order-21 {
  order: 21;
}
.flex-col-22 {
  width: 91.66666667%;
}
.flex-offset-22 {
  margin-left: 91.66666667%;
}
.flex-order-22 {
  order: 22;
}
.flex-col-23 {
  width: 95.83333333%;
}
.flex-offset-23 {
  margin-left: 95.83333333%;
}
.flex-order-23 {
  order: 23;
}
.flex-col-24 {
  width: 100%;
}
.flex-offset-24 {
  margin-left: 100%;
}
.flex-order-24 {
  order: 24;
}
.flex-row.flex-left {
  justify-content: flex-start;
}
.flex-row.flex-center {
  justify-content: center;
}
.flex-row.flex-right {
  justify-content: flex-end;
}
.flex-row.flex-top {
  align-items: flex-start;
}
.flex-row.flex-middle {
  align-items: center;
}
.flex-row.flex-bottom {
  align-items: flex-end;
}
.align-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.align-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.relative {
  position: relative;
}
.block {
  display: block;
}
.inline {
  display: inline-block;
}
[hidden] {
  display: none !important;
}
.border-none {
  border: none!important;
}
.badge {
  padding: 0 5px;
  border-radius: 34px;
}
.absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 30;
}
.mask {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.mask-icon {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.h1 {
  font-size: 32px;
}
.h2 {
  font-size: 24px;
}
.h3 {
  font-size: 18px;
}
.h4 {
  font-size: 16px;
}
.h5 {
  font-size: 14px;
}
.h6 {
  font-size: 12px;
}
.bold {
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.italic {
  font-style: italic;
}
.word-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.word-line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.show-wrap {
  white-space: pre-line;
}
.wrap {
  word-wrap: break-word;
}
.no-wrap {
  white-space: nowrap;
}
.white-bg {
  background-color: #fff;
}
.pointer {
  cursor: pointer;
}
.float-right {
  float: right;
}
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
#root {
  height: 100%;
}
.ant-layout {
  height: 100%;
}
.ant-layout .logo {
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  margin: 16px;
}
.ant-layout .ant-layout-sider-collapsed .anticon {
  font-size: 16px;
}
.ant-layout .ant-layout-sider-collapsed .nav-text {
  display: none;
}
.ant-layout .ant-layout-sider-collapsed .ant-menu-submenu-vertical > .ant-menu-submenu-title:after {
  display: none;
}
.ant-layout .ant-layout-sider-collapsed .ant-menu-dark:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: inherit;
}
.ant-layout p {
  margin: 10px 0 10px 0;
}
/**
*Class	描述
.active	鼠标悬停在行或单元格上时所设置的颜色
.success	标识成功或积极的动作
.info	标识普通的提示信息或动作
.warning	标识警告或需要用户注意
.danger	标识危险或潜在的带来负面影响的动作
*/
/*定制主题
https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
由于primary-*是基于primary-color计算，暗色主题颜色会生成灰色primary-1,手动指定亮色
*/
