/* Document
   ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

@import "./variable.less";
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
     ========================================================================== */

/**
   * Remove the margin in all browsers.
   */

body {
  margin: 0;
  font-size: 14px;
}

/**
   * Render the `main` element consistently in IE.
   */

main {
  display: block;
}

/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
     ========================================================================== */

/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
     ========================================================================== */

/**
   * Remove the gray background on active links in IE 10.
   */

body a {
  text-decoration: none;
//   color: rgb(64, 159, 231);
color: #166eff;
  text-decoration-color: initial;
  background-color: transparent;
  outline-color: initial;
  transition: color 0.3s;
}

// a:link {
//     color: #fff;
// }

// a:visited {
//     color: #fff;
// }

body a:hover {
  text-decoration-color: initial;
  outline-color: initial;
  color: rgb(89, 172, 232);
}

// a:active {
//     color: #fff;
// }

/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */

b,
strong {
  font-weight: bolder;
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
   * Add the correct font size in all browsers.
   */

small {
  font-size: 80%;
}

/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
     ========================================================================== */

/**
   * Remove the border on images inside links in IE 10.
   */

img {
  border-style: none;
}

/* Forms
     ========================================================================== */

/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
   * Remove the inner border and padding in Firefox.
   */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
   * Restore the focus styles unset by the previous rule.
   */

// button:-moz-focusring,
// [type="button"]:-moz-focusring,
// [type="reset"]:-moz-focusring,
// [type="submit"]:-moz-focusring {
//     outline: 1px dotted ButtonText;
// }

/**
   * Correct the padding in Firefox.
   */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */

progress {
  vertical-align: baseline;
}

/**
   * Remove the default vertical scrollbar in IE 10+.
   */

textarea {
  overflow: auto;
}

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

// remove arrow when type equal number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
     ========================================================================== */

/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */

details {
  display: block;
}

/*
   * Add the correct display in all browsers.
   */

summary {
  display: list-item;
}

/* Misc
     ========================================================================== */

/**
   * Add the correct display in IE 10+.
   */

template {
  display: none;
}

/**
   * Add the correct display in IE 10.
   */

[hidden] {
  display: none;
}

HTML,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
p,
blockquote,
pre,
form,
fieldset,
table,
th,
td,
i {
  border: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  // font-size: 14px;
  margin: 0px;
  padding: 0px;
  // cursor: url(../assets/pointer.png) 8 3, auto;
  outline: none;
}
div{
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

input {
  -webkit-appearance: none;
  margin: 0;
  outline: none;
  padding: 0;
}

input::-webkit-input-placeholder {
  color: #ccc;
}

input::-ms-input-placeholder {
  color: #ccc;
}

input::-moz-placeholder {
  color: #ccc;
}

input[type="submit"],
input[type="button"] {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

img {
  border: none;
}

ul,
ol,
li {
  list-style-type: none;
}
th {
  font-weight: 700;
}

// global style
::selection {
  text-shadow: none;
  background: @theme-color;
}
::-webkit-selection {
  background: @theme-color;
  color: #fff;
}
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
body::-webkit-scrollbar {
  display: none;
}

p {
  margin: 0;
  padding: 0;
}
table.pure-table {
  table-layout: fixed;
    border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px 2px 0 0;
  th,
  td {
    word-break: break-all;
    padding: 8px 20px 8px 0;
  }
  &.bordered {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    th,
    td {
    padding: 8px;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
    }
  }
}
iframe{
	box-sizing: border-box;
	outline: none;
	border: none;
	body{
		background-color: #fff;
	color: #000;
	}
}
.w-100 {
  width: 100%;
}
.h-100{
	height: 100%;
}
.danger-color {
  color: @danger;
  &:hover {
    color: @warning;
  }
}
// 直接placeholder开发模式导致input默认是placeholder样式
.placeholder-color {
  color: #bfbfbf;
}
.grey-color{
	    color: rgb(144, 149, 153);
}
.red-color {
  color: @red-color;
}
.pink-color{
	color: #FF3A77;
}
.operate-color {
  color: #0080c2;
}
[theme-color] {
  color: @theme-color!important;
}
[theme-bg] {
  background-color: @theme-color!important;
  border-color: @theme-color!important;
  color: #fff !important;
  &:disabled {
    background-color: #d07b83 !important;
    border-color: #d07b83 !important;
  }
}
.ant-breadcrumb > span {
  color: #000000;
}

.ant-breadcrumb-separator {
  margin: 0 10px !important;
}
  //避免小屏幕未指定宽度的表头宽度被压缩至0
.ant-table colgroup col{
	min-width: 80px;
}
.ant-table .ant-table-header th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #c1c1c1;
}
.rdw-editor-main .DraftEditor-root {
  height: auto;
}
// // 在线引用iconfont
//  .anticon {
//        width: 1em; height: 1em;
//        vertical-align: -0.15em;
//        fill: currentColor;
//        overflow: hidden;
//     }
.ant-modal .ant-modal-footer {
  text-align: center;
}
.ant-spin-container {
  height: 100%;
}
.drawer-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: right;
  padding: 14px 24px;
  box-shadow: 0 -1px 0 0 rgb(218 218 218 / 50%);
  .ant-btn {
    margin-left: 8px;
  }
}
.ant-modal-fullscreen {
  position: absolute !important;
  top: 0;
  right: 56px;
  z-index: 10;
  margin: 12px 4px;
  color: rgba(42, 43, 60, 0.45) !important;
}
.ant-layout .ant-breadcrumb .ant-breadcrumb-link,
.ant-layout .ant-breadcrumb a {
  color: rgba(42, 43, 60, 0.85);
}
.ant-layout .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #e3eeff;
  color: #166eff;
  position: relative;
}
// tree 可全行选中
.ant-select-tree-list-holder-inner,.ant-tree-list-holder-inner{
	display: block!important;
}
.ant-tree-node-content-wrapper{
	flex-grow: 1;
}
.margin-t-20 {
  margin-top: 20px;
}
.white-page{
  background-color: #fff;
  padding: 14px 20px;
}
.on-row {
      table tbody tr {
        cursor: pointer;
        &:hover {
          color: @primary-color;
        }
      }
    }

  .cut-line{
	height: 20px;
	margin: 10px -24px 0 -24px;
	border-bottom: 10px solid #F2F5F7;
  }
  .mt5{
	margin-top: 5px;
  }
  .mt8{
	margin-top: 8px;
  }
  .f-14{
	font-size: 14px;
  }
  .f-24{
	font-size: 24px;
  }
  .f-18{
	font-size: 18px;
  }
  .fw-600{
	font-weight: 600;
  }
  .ml7 {
    margin-left: 7px;
}
a.link:hover{
	text-decoration: underline;
}
.mt10{
	margin-top: 10px;
}
.mb10{
	margin-bottom: 10px;
}
.b-r{
	border-right: 1rpx solid @border-color;
}
.num-border{
	display: inline-block;
	min-width: 34px;
	text-align: center;
	padding: 4px 8px;
	line-height: 1;
border: 1px solid #007EFF;
border-radius: 3px;
color: #007EFF;
}
.blue-color{
	color: #166EFF;
}
.mw-100{
	max-width: 100%!important;
}