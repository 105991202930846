.ant-layout .ant-menu-root.ant-menu-inline,
.ant-layout .ant-menu-root.ant-menu-vertical {
  background: #fff !important;
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline a,
.ant-layout .ant-menu-root.ant-menu-vertical a {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu {
  color: #616161 !important;
  background-color: #fff !important;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu > .ant-menu-submenu-title:hover,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu > .ant-menu-submenu-title:hover {
  background-color: #EBEBEB;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-selected,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-selected {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-active,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-active {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover {
  color: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #616161;
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-submenu > .ant-menu,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-submenu > .ant-menu {
  background-color: rgba(48, 126, 255, 0.15);
}
.ant-layout .ant-menu-root.ant-menu-inline .ant-menu-item > a:hover,
.ant-layout .ant-menu-root.ant-menu-vertical .ant-menu-item > a:hover {
  color: #616161;
}
#root .ant-menu .ant-menu-item-selected {
  background-color: #E3EEFF !important;
  color: #166EFF !important;
  position: relative;
}
#root .ant-menu .ant-menu-item-selected::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #166EFF;
}
#root .ant-menu .ant-menu-item-selected span a {
  color: #166EFF !important;
}
#root .ant-menu .ant-menu-item {
  color: #616161;
}
#root .ant-menu .ant-menu-item span a {
  color: #616161;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  color: #616161;
}
[class*=btn] {
  cursor: pointer;
}
.bg--primary {
  background: #166EFF;
}
.header-logo {
  color: #fff;
  line-height: 48px;
  text-align: center;
  font-size: 17px;
  display: flex;
  align-items: center;
}
.header-logo .logo-circle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #153ad1;
  border-radius: 50%;
  margin-right: 20px;
}
.header-logo .logo-circle img {
  width: 26px;
  border-radius: 50%;
  height: auto;
  object-fit: cover;
}
.header {
  background-color: #166EFF !important;
  padding: 0 40px;
  height: 48px !important;
  line-height: 48px !important;
  align-items: center;
  justify-content: space-between;
  color: #fff !important;
}
.header .header-right {
  display: flex;
  align-items: center;
  color: #c7c7c7;
}
.header .contact-us {
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
  color: #fff;
}
.header .contact-us::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 16px;
  bottom: 16px;
  width: 1px;
  background: #fff;
}
.header .contact-us span {
  margin-left: 8px;
}
.header .userinfo {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.header .userinfo img {
  margin-right: 20px;
}
.header .userinfo p:nth-child(2) {
  margin: 0 6px;
}
.header .userinfo p:nth-child(2) span {
  font-size: 16px;
  color: #fff;
}
.header .ant-menu {
  background: transparent;
  color: #ffffff;
}
.header .ant-menu .ant-menu-item:hover {
  color: #ffffff;
}
.header__trigger {
  color: #ffffff;
}
.ant-layout-content {
  min-height: auto;
  margin: 8px 16px;
}
.ant-layout.ant-layout-has-sider.app_layout-mobile {
  flex-direction: column;
}
.ant-layout.ant-layout-has-sider.app_layout-mobile .ant-layout-content {
  margin: 0;
}
.ant-layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.ant-layout .ant-layout-sider-children .ant-spin-nested-loading {
  flex: 1 1 0%;
  overflow: hidden auto;
}
.ant-layout .ant-layout-sider-children .side-link {
  padding-left: 16px;
  line-height: 40px;
  border-top: 1px solid #3e4141;
  color: #fff;
}
.ant-layout .ant-menu-root.ant-menu-inline,
.ant-layout .ant-menu-dark .ant-menu-item {
  color: rgba(255, 255, 255, 0.85);
}
.ant-layout .ant-menu-dark .ant-menu-item:hover {
  color: #fff;
}
.wrapper-admin-box {
  position: relative;
}
.admin-box {
  position: relative;
  margin: 0;
  padding: 14px 16px;
  height: calc(100vh - 48px);
  overflow: auto;
}
.admin-box div:last-child:fullscreen {
  background-color: #f5f5f5;
}
.ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
*Class	描述
.active	鼠标悬停在行或单元格上时所设置的颜色
.success	标识成功或积极的动作
.info	标识普通的提示信息或动作
.warning	标识警告或需要用户注意
.danger	标识危险或潜在的带来负面影响的动作
*/
/*定制主题
https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
由于primary-*是基于primary-color计算，暗色主题颜色会生成灰色primary-1,手动指定亮色
*/
