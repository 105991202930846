// variable
@image: '../../images';
@active-color: #cc3534;
@yellow-color: #ffb426;

// function
.relative {
    position: relative;
}



// 子项

.grow {
    flex-grow: 1;
}

// 压缩空间
.shrink {
    flex-shrink: 1;
}

// 不压缩空间
.no-shrink {
    flex-shrink: 0;
}

// 文字截断一行
.nowarp-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// 文字以行数截断
.clamp-line(@line) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: @line;
    -webkit-box-orient: vertical;
}

// style
.hidden {
    display: none !important;
}
// 徽章
.badge{
        background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
}
// 自适应内容的圆，单行
.circle-container {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 1;

    span {
        display: block;
        padding: 50%;
        margin: -3em -50% 0;
        position: relative;
        top: 1.5em;
        border: 1em solid transparent;
        white-space: nowrap;
    }
}


// 遮罩
.shader {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

// 淡出效果
.fade-in {
    position: absolute;
    animation: fadein .8s cubic-bezier(.25, .5, .5, .9);
    bottom: 0;
}

@keyframes fadein {
    from {
        bottom: -100%;
    }

    to {
        bottom: 0;
    }
}
// 颜色定义规范
@grey-color:#909399;
// basice
@font-size: 14px;
@icon-size:17px;
@active-color:#19AA8D;
@blue-color:#007EFF;
@red-color:#FF0000;
@bg-main:#F8F8F8;//右侧内容区域背景
@theme-color:#166EFF;//主题色
@border-color: #dbdbdb;
@white-bg:#fff;
@el-icon-small:30px;
@el-icon-mini:24px;
@box-shadow:0 10px 10px -10px rgb(223, 222, 222,.6);
/**
*Class	描述
.active	鼠标悬停在行或单元格上时所设置的颜色
.success	标识成功或积极的动作
.info	标识普通的提示信息或动作
.warning	标识警告或需要用户注意
.danger	标识危险或潜在的带来负面影响的动作
*/
// 状态
@active:#E32E3F;
@success: #5cb85c;
@info:    #5bc0de;
@warning: #f0ad4e;
@danger:  #d9534f;
// aside
@width-aside:220px; 
@width-hideside-aside:54px;
@width-mobile-aside:210px;
@color-aside:rgba(255, 255, 255,.9);
@icon-arrow-size-aside:12px;
@width-submenu-aside:55px;
@height-aside-tilte:64px;
@height-aside-img:14px;
@width-aside-img:50px;
@menu-bg:#fff;
@menu-selected-bg:#E3EEFF;
@menu-font-color:#616161;
@menu-item-color: @menu-font-color;
@menu-selected-font-color:#166EFF;
@menu-dark-selected-item-text-color:@menu-selected-font-color;
@layout-sider-background:@menu-bg;
@menu-item-hover-bg:#EBEBEB;
@menu-dark-item-hover-bg:@menu-item-hover-bg;
// header
@height-header: 48px;
// nav-scroll
@height-nav-scroll:40px;
@active-bg-tabs-item-nav-scroll:#409eff;
@bg-tabs-item-nav-scroll:#ddd;
// table
@bg-color-table-thead:#fafafa;
@border-color-table:#ededed;
@height-table-cell:45px;
@color-table-tbody:#595959;
@color-table-thead:#262626;
// dashboard
@height-car:68px;
// mobile
@padding-xs: 5px;
@margin-xs: 5px;
//tabs
@tabs-content:calc(100vh - 201px);
// other
@card-gutter:6px;


@full-black:  		rgba(0, 0, 0, 1);
@dark-black:  		rgba(0, 0, 0, 0.87);
@light-black: 		rgba(0, 0, 0, 0.54);
@min-black:   		rgba(0, 0, 0, 0.065);

@full-white:  		rgba(255, 255, 255, 1);
@dark-white:  		rgba(255, 255, 255, 0.87);
@light-white: 		rgba(255, 255, 255, 0.54);
@min-white:   		rgba(255, 255, 255, 0.1);

@primary:           #0cc2aa;
@accent:            #a88add;
@warn:              #fcc100;

@info:              #6887ff;
@success:           #6cc788;
@warning:           #f77a99;
@danger:            #f44455;

@light:             #f8f8f8;
@grey:              #424242;
@dark:              #2e3e4e;
@black:				#2a2b3c;
@white:				#ffffff;

@primary-color:     @dark-white;
@accent-color:		@dark-white;
@warn-color:		@dark-white;
@success-color:		@dark-white;
@info-color:		@dark-white;
@warning-color:		@dark-white;
@danger-color:     	@dark-white;
@light-color:		@dark-black;
@grey-color:		@dark-white;
@dark-color:		@dark-white;
@black-color:		@dark-white;

//@border-color:	 	rgba(120, 130, 140, 0.13);

@spacer:            1rem;

// 基础颜色
@white: #ffffff;
@primary-color: @theme-color;
@link-color: @primary-color; // 链接色
@success-color: #52c41a; // 成功色
@warning-color: #faad14; // 警告色
@error-color: #f5222d; // 错误色
@font-size-base: 14px; // 主字号
@primary-color-light: fade(lighten(@primary-color, 5%), 15%);
// 顶部背景色
@layout-header-background:@primary-color;
// 左边菜单light颜色
@layout-sider-background-light: #f9f9f9;
// 字体颜色
@text-color: #000000;
@table-selected-row-bg: #fbfbfb;
@primary-2: @primary-color-light;
// 基础圆角
@border-radius-base: 2px;
// 输入框后缀背景色
// @input-addon-bg: @primary-color;
@border-radius-base: 3px;
/*定制主题
https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
由于primary-*是基于primary-color计算，暗色主题颜色会生成灰色primary-1,手动指定亮色
*/
@primary-1: #e6f7ff;
@primary-2: #bae7ff;
@primary-3: #91d5ff;
@primary-4: #69c0ff;
@primary-5: #40a9ff;
@primary-6: #1890ff;
@primary-7: #096dd9;
@menu-inline-submenu-bg:rgba(255, 255, 255, 0.85);

