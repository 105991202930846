.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  background-image: url("/static/images/login-bg.jpg");
  background-size: 100% 100%;
  background-color: #eff7ff;
  .login-box {
    width: 1000px;
    margin: auto;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    //   background-image:url('/static/images/login-bg2.png');
    background-image: url("/static/images/login-right.png");
    background-size: 100% 100%;
    .login-left {
      width: 50%;
      color: #fff;
      padding: 40px;
      background-image: linear-gradient(to right bottom, #24c7fc, #0348f9);
	  .top-line{
		width: 51px;
height: 8px;
background: #FFFFFF;
border-radius: 4px;
margin-bottom: 33px;
	  }
      h1 {
        margin-bottom: 24px;
      }
      h2 {
        font-size: 16px;
        letter-spacing: 2px;
      }
      .h1-line {
        width: 40px;
        height: 2px;
        background: #fff;
      }
      h1,
      h2 {
        color: #fff;
      }
      img {
        width: 350px;
        object-fit: contain;
      }
    }
  }
  .login-form-box {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-form {
    width: 400px;
    // box-shadow: 0px 2px 18px 0px rgb(12 29 42 / 10%);
    position: relative;
    flex-shrink: 0;
    border-radius: 4px;
    h1 {
      font-size: 35px;
      font-weight: bold;
      color: #4e6ef2;
      margin-bottom: 50px;
      position: relative;
      display: inline-block;
      margin-left: 50%;
	  letter-spacing: 6px;
      transform: translateX(-50%);
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        height: 4px;
        background: #cce4ff;
        border-radius: 3px;
      }
    }
    .ant-input-affix-wrapper {
      padding: 9px 16px;
    }
    .login-logo {
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      margin-bottom: 24px;
      span {
        vertical-align: text-bottom;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }
  .register-operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .register-btn {
      font-weight: 500;
      color: #4e6ef2;
    }
    span.register-tip {
      color: #828282;
      margin-right: 6px;
    }
  }
  .copyright {
    position: fixed;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    font-weight: 400;
    color: #e4e4e4;
    img {
      height: 20px;
      object-fit: cover;
    }
  }
}
.installer {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #313653 transparent transparent;
  position: fixed;
  top: 0;
  right: 0;
}
.installer__btn {
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  left: 50px;
  position: absolute;
  background-size: cover;
}
