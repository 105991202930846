//栅格
@column: 12;
.generate-col(@i) when (@i>0) {
  .col-@{i} {
    width: percentage((@i/@column));
  }
  .col-offset-@{i} {
    margin-left: percentage((@i/@column));
  }
  .generate-col(@i - 1);
}

.generate-col(@column);
//对齐方式
.left{
  margin-right: auto;
}
.right{
  margin-left: auto;
}
.top{
  margin-bottom:auto;
}
.bottom{
  margin-top:auto;
}
.center{
  margin-left: auto;
  margin-right: auto;
}
.middle{
  margin-top:auto;
  margin-bottom: auto;
}
.inline-flex{
	display: inline-flex;
}
//flex layout http://coffcer.github.io/flex-layout/#example
.flex{
  display: flex;
}
.flex-left{
    justify-content: left;
}
.flex-center{
	justify-content: center;
}
.flex-right{
    justify-content: flex-end;
}
.flex-top{
    align-items: flex-start;
}
.flex-middle{
	align-items: center;
}
.flex-bottom{
    align-items: flex-end;
}
.flex-shrink-0{
  flex-shrink:0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow{
	flex-grow: 1;
}

.flex-around {
  justify-content: space-around;
}
.flex-between{
	justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-baseline {
  align-items: baseline;
}
@flex-column: 24;
.build-col(@i) when (@i>0) {
  .build-col(@i - 1);
  //栅格
  .flex-col-@{i} {
    width: percentage((@i / @flex-column));
  }
  //偏移
  .flex-offset-@{i} {
    margin-left: percentage((@i / @flex-column));
  }
  //排序
  .flex-order-@{i} {
    order: @i;
  }
}

.build-col(@flex-column);
//flex-row对齐方式
.build-align(@type) when (@type=row) {
  &.flex-left {
    justify-content: flex-start;
  }
  &.flex-center {
    justify-content: center;
  }
  &.flex-right {
    justify-content: flex-end;
  }
  &.flex-top {
    align-items: flex-start;
  }
  &.flex-middle {
    align-items: center;
  }
  &.flex-bottom {
    align-items: flex-end;
  }
}

.flex-row {
  .build-align(row);
}

.build-align(@type) when (@type=column) {
  &.left {
    align-items: flex-start;
  }
  &.flex-center {
    align-items: center;
  }
  &.flex-end {
    align-items: flex-end;
  }
  &.flex-top {
    justify-content: flex-start;
  }
  &.flex-middle {
    justify-content: center;
  }
  &.flex-bottom {
    justify-content: flex-end;
  }
}

// .flex-between {
//   justify-content: space-between;
//   .build-align(column);
// }
//自适应
//@media (max-width: 992px) {
//  .flex-md {
//    flex-direction: column;
//    .build-align(row);
//
//    [class*=flex-item] {
//      width: 100%;
//    }
//  }
//}

// 上下居中
.align-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

// 左右分开占位
.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// 完全居中
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

//上下分开占位
.align-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

//上下分开占位并且左右居中
.align-between-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

//上下左右居中
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}