.new-detail{
	>*{
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.news-intro{
		font-size: 16px;
		color: #a8a8a8;
	}
    img{
        object-fit: contain;
    max-width: 100%;
    height: auto;
    margin-bottom: 6px;
    }
}