.register {
  background: #fff;

  .login-form-box {
    display: flex;
    position: relative;
	justify-content: center;
	padding: 60px 50px;
    .back-btn {
      position: absolute;
      left: 50px;
      border: 1px solid #d8d8d8;
      border-radius: 17px;
      color: #4e6ef2;
      padding: 2px 16px ;
    }
  }
  .login-form {
	width: 800px;
    // box-shadow: 0px 2px 18px 0px rgb(12 29 42 / 10%);
    position: relative;
    h1 {
      font-size: 35px;
      font-weight: bold;
      color: #828282;
      margin-bottom: 50px;
      position: relative;
      display: inline-block;
      margin-left: 50%;
      transform: translateX(-50%);
	  letter-spacing: 6px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 6px;
        height: 4px;
        background: #CCE4FF;
        border-radius: 3px;
      }
    }
    .ant-input-affix-wrapper {
    //   padding: 9px 16px;
    }
    .login-logo {
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      margin-bottom: 24px;
      span {
        vertical-align: text-bottom;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }
}
