/* 淡入淡出 */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.6s;
}
.fade-leave-active {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
/* 淡入 */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 淡出 */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* 弹跳 */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/* 弹入 */
@keyframes bouncein {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
/* 弹出 */
@keyframes bounceout {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}
/* 弹入-从上 */
@keyframes bounceinT {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
/* 弹入-从下 */
@keyframes bounceinB {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
/* 转入 */
@keyframes rotatein {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}
/* 翻转 */
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}
/* 震颤 */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
/* 震铃 */
@keyframes ring {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
/*左滑*/
@keyframes left-slide {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
/*右滑*/
@keyframes right-slide {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}
/*下滑*/
@keyframes pulldown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}
/*  上滑 */
.pullup-enter-active {
  animation: pullup 0.6s;
  animation-fill-mode: forwards;
}
@keyframes pullup {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
  70% {
    transform: translateY(-110%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.pullup-leave-active {
  animation: pullup-leave 0.6s;
  animation-fill-mode: forwards;
}
@keyframes pullup-leave {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
/* 放大 */
.zoom-enter-active {
  animation: dialog-zoom 0.4s;
}
.zoom-leave-active {
  animation: zoom-leave 0.4s;
}
/* zoom */
@keyframes dialog-zoom {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoom-leave {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
/**
*Class	描述
.active	鼠标悬停在行或单元格上时所设置的颜色
.success	标识成功或积极的动作
.info	标识普通的提示信息或动作
.warning	标识警告或需要用户注意
.danger	标识危险或潜在的带来负面影响的动作
*/
/*定制主题
https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
由于primary-*是基于primary-color计算，暗色主题颜色会生成灰色primary-1,手动指定亮色
*/
