.ant-layout-content {
  min-height: auto;
  margin: 8px 16px;
}

.ant-layout {
  &.ant-layout-has-sider {
    &.app_layout-mobile {
      flex-direction: column;
      .ant-layout-content {
        margin: 0;
      }
    }
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    .ant-spin-nested-loading {
      flex: 1 1 0%;
      overflow: hidden auto;
    }
    .side-link {
      padding-left: 16px;
      line-height: 40px;
      border-top: 1px solid rgb(62, 65, 65);
      color: #fff;
    }
  }
  .ant-menu-root.ant-menu-inline,
  .ant-menu-dark .ant-menu-item {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-dark .ant-menu-item {
    &:hover {
      color: #fff;
    }
  }
}
