//防止showDrawer被amdin-box overflow影响导致跟随滚动
.wrapper-admin-box{
	position: relative;
}
.admin-box{
	position: relative;
	margin: 0;
	padding: 14px 16px;
	height: calc(100vh - @height-header);
	overflow: auto;
	div:last-child:fullscreen{
		background-color: #f5f5f5;
	}
}
// > popover
// .ant-modal-mask,.ant-modal-wrap{
// 	z-index: 1031;
// }
// 修改treenode节点 树图标不居中
.ant-tree-switcher{
	     display: flex; 
     align-items: center; 
     justify-content: center;
}