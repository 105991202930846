
.header-logo {
  color: #fff;
  line-height: @height-header;
  text-align: center;
  font-size: 17px;
  display: flex;
  align-items: center;
  .logo-circle {
    width: 40px;
    height: 40px;
	display:flex;
	justify-content:center;
	align-items:center;
	text-align: center;
    background: #153ad1;
    border-radius: 50%;
    margin-right: 20px;
	img{
		width: 26px;
		border-radius: 50%;
		height: auto;
		object-fit: cover;
	}
  }
}
.header {
	background-color: @primary-color!important;
    padding: 0 40px;
    height: @height-header!important;
	line-height: @height-header!important;
	align-items: center;
	justify-content: space-between;
	color: #fff!important;
	.header-right{
		display: flex;
		align-items: center;
		color:#c7c7c7;
	}
	.contact-us{
		display:flex;
		align-items: center;
		padding-right:20px;
		margin-right:20px;
		position: relative;
		color:#fff;
		&::after{
			content:'';
			display: block;
			position: absolute;
			right:0;
			top:16px;
			bottom:16px;
			width:1px;
			background:#fff;
		}
		span{
			margin-left:8px;
		}
	}
	.userinfo{
		// width: 100px;
		display:flex;
		align-items:center;
		color:#fff;
		cursor:pointer;
		img{
			margin-right: 20px;
		}
		p:nth-child(2){
			margin:0 6px;
			span{
				font-size:16px;
				color:#fff;
			}
		}
	}
    .ant-menu {
        background: transparent;
        color: @white;
        .ant-menu-item {
            &:hover {
                color: @white;
            }
        }
    }
}
.header__trigger {
    color: @white;
}
