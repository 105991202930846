.ant-layout .ant-menu-root {
  &.ant-menu-inline,
  &.ant-menu-vertical {
    background: @menu-bg!important;
    // border-right: 1px solid @menu-bg;
    color: @menu-font-color;
    a {
      color: @menu-font-color;
    }
	.ant-menu-submenu,.ant-menu{
    color: @menu-font-color!important;
    background-color: @menu-bg!important;
	}
	.ant-menu-submenu > .ant-menu-submenu-title:hover{
		background-color: @menu-item-hover-bg;
	}
    .ant-menu-submenu-selected {
      color: @menu-font-color;
    }
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active {
      color: @menu-font-color;
    }
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: @menu-font-color;
        }
      }
      &:hover {
        color: @menu-font-color;
        .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background: @menu-font-color;
          }
        }
      }
    }
    .ant-menu-submenu > .ant-menu {
      background-color: @primary-color-light;
    }
    .ant-menu-item > a:hover {
      color: @menu-font-color;
    }
  }
}
#root   .ant-menu{
    .ant-menu-item-selected {
      background-color: @menu-selected-bg !important;
      color: @menu-selected-font-color !important;
      position: relative;
      &::before {
		content: '';
		display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        background: @menu-selected-font-color;
      }
      span a {
        color: @menu-selected-font-color !important;
      }
    }
    .ant-menu-item {
      color: @menu-font-color;
      span a {
        color: @menu-font-color;
      }
    }
  }
.ant-menu-horizontal {
  > .ant-menu-item-selected {
    color: @menu-font-color;
  }
}
