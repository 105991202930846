/* show-loading */
.uface-loading-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 90vh; */
  /* min-height: 90vh; */
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.uface-loading-wrp > h1 {
  font-size: 24px;
  font-weight: bolder;
}

.uface-loading-wrp .loading-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

.uface-loading-wrp .loading-wrp .dot {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 64px;
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}

.uface-loading-wrp .loading-wrp .dot i {
  position: absolute;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #313653;
  border-radius: 100%;
  opacity: 0.3;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  animation: antSpinMove 1s infinite linear alternate;
}

.uface-loading-wrp .loading-wrp .dot i:nth-child(1) {
  top: 0;
  left: 0;
}

.uface-loading-wrp .loading-wrp .dot i:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.uface-loading-wrp .loading-wrp .dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.uface-loading-wrp .loading-wrp .dot i:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
