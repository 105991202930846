//元素
.w-100{
	width: 100%;
}
.h-100{
	height: 100%;
}
.relative {
  position: relative;
}
.block{
    display: block;
}
.inline{
    display: inline-block;
}
[hidden]{
  display: none !important;
}
.border-none{
  border: none!important;
}
.badge{
  padding: 0 5px;
  border-radius: 34px;
}
//绝对定位-居中
.absolute-center{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 30;
}
// 遮罩
.mask{
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.mask-icon{
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
//文本
.h1{
	font-size: 32px;
}
.h2{
	font-size: 24px;
}
.h3{
	font-size:18px
}
.h4{
	font-size: 16px;
}
.h5{
	font-size: 14px;
}
.h6{
	font-size: 12px;
}
.bold{
  font-weight: bold;
}
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.italic{
        font-style: italic;
}


//文本
// 文字截断一行
.word-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 文字以行数截断
.word-line-by(@line:2) {
  overflow: hidden;
  text-overflow: ellipsis;
//   display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}
// 多行截断
.word-line2{
	.word-line-by();
}
//显示换行
.show-wrap{
  white-space:pre-line;
}
.wrap{
  word-wrap:break-word;
}
.no-wrap{
  white-space: nowrap;
}
// bg-colo
.white-bg{
    background-color: #fff;
}
// cursor
.pointer{
    cursor: pointer;
}
// float
.float-right{
    float: right;
}
.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}